import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import AlarmReport from "$pages/reports-react/alarm-report/alarm-report";

class AlarmReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(AlarmReport);
  }
}

export default AlarmReportReactWrapper
