import React from 'react'
import {
  AlarmReportDocument,
  AlarmReportDownloadDocument,
  AlarmReportDownloadQuery,
  AlarmReportQuery,
  AlarmReportQueryVariables,
  Reports,
  SortDirection
} from "$typings/graphql-codegen";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import { formatDate, humanReadableDurationMs } from '$lib/dateHelpers';
import {ReportGridDataHook} from "$pages/reports-react/generic-report-hooks";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

type ReportResult = AlarmReportQuery
type ReportDownloadResult = AlarmReportDownloadQuery
type ReportVariables = AlarmReportQueryVariables
type ReportData = AlarmReportQuery["reports"]["alarm"]["data"][0]

enum ColumnKey {
  SiteId = 'siteId',
  AlarmType = 'alarmType',
  SiteName = 'siteName',
  ChannelName = 'channelName',
  CustomerName = 'customerName',
  Triggered = 'triggered',
  AlarmDuration = 'alarmDuration',
}

const AlarmReport = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_Alarm')
  const Filename: string = 'alarm-report'
  
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.SiteName,
    sortDirection: SortDirection.Asc
  }
  
  function toYT3AlarmType(number: number): string {
    switch (number) {
      case 1: return 'Custom';
      case 2: return 'Low level';
      case 3: return 'Pre low level';
      case 4: return 'Configuration';
      case 5: return 'Offline';
      case 6: return 'Percent capacity 30';
      case 7: return 'Maintenance';
      case 8: return 'Dose calibration';
      case 9: return 'Serious dose calibration';
      case 10: return 'Broken sensor';
      default: return '';
    }
  }
  
  function CastDate(value: Date | number | string | null): Date {
    return new Date(value ?? '')
  }
  
  function formatDateString(value: string | null): string {
    return formatDate((CastDate)(value), true, '.')
  }
  
  function formatDuration(duration: number | null): string {
    if (!duration) return ''
    type Duration = { key: string, count: number }
    const durationObject: Duration = humanReadableDurationMs(duration)
    const translationSlug: string = durationObject.key
    const count: number = durationObject.count
    const translatedDurationString: string = t(translationSlug, { count })
    return [count.toString(), translatedDurationString].join(' ')
  }
  
  const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Site'),
      (row: ReportData) => row[ColumnKey.SiteName] ?? '',
      ColumnKey.SiteName,
      ColumnWidth.Common.SiteName
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Customer'),
      (row: ReportData) => row[ColumnKey.CustomerName] ?? '',
      ColumnKey.CustomerName,
      ColumnWidth.Common.CustomerName
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_AlarmType'),
      (row: ReportData) => toYT3AlarmType(row[ColumnKey.AlarmType]),
      ColumnKey.AlarmType,
      ColumnWidth.Common.AlarmType
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_ChannelName'),
      (row: ReportData) => row[ColumnKey.ChannelName] ?? '',
      ColumnKey.ChannelName,
      ColumnWidth.Common.ChannelName
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_Triggered'),
      (row: ReportData) => formatDateString(row[ColumnKey.Triggered] || null),
      ColumnKey.Triggered,
      ColumnWidth.Generic.DateTime
    ),
    new ColumnDefinition<ReportData>(
      t('UI_Reports_AlarmDuration'),
      (row: ReportData) => formatDuration(row[ColumnKey.AlarmDuration] || null),
      ColumnKey.AlarmDuration,
      ColumnWidth.Common.AlarmDuration
    ),
  ]
  
  function dataSelector(result: ReportResult): ReportData[] {
    return result?.reports?.alarm?.data as ReportData[]
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.alarm?.blobUrl ?? ''
  }
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()

  function createTitle(data: ReportGridDataHook<ReportData>): JSX.Element {
    const n: number = data?.data?.length ?? 0
    const text: string = n ? `${ Title } - ${n} alarms` : Title
    return <div>{text}</div>
  }
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.Alarm}
    mainQueryDocument={AlarmReportDocument}
    downloadQueryDocument={AlarmReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename} />
}

export default AlarmReport
